import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import ArticleScreen from '../screens/article'
import ArticlesScreen from '../screens/articles'

const ArticlesPage = (props) => (
  <Router>
    <ArticlesScreen {...props} filterType="category" path={`${_.get(props, 'pageContext.locale')}/articles/`} />
    <ArticlesScreen {...props} filterType="category" path={`${_.get(props, 'pageContext.locale')}/articles/category/:slug`} />
    <ArticlesScreen {...props} filterType="tag" path={`${_.get(props, 'pageContext.locale')}/articles/tag/:slug`} />
    <ArticleScreen {...props} filterType="category" path={`${_.get(props, 'pageContext.locale')}/articles/category/:filterSlug/:slug/:id`} />
    <ArticleScreen {...props} filterType="tag" path={`${_.get(props, 'pageContext.locale')}/articles/tag/:filterSlug/:slug/:id`} />
    <ArticleScreen {...props} path={`${_.get(props, 'pageContext.locale')}/articles/:slug/:id`} />
    <ArticleScreen {...props} path={`${_.get(props, 'pageContext.locale')}/articles/:id`} />
  </Router>
)

export default ArticlesPage
