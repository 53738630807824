import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    padding: [[50, 20, 35]],
    width: '100%',
    textAlign: 'left',
  },
  titleStyle: {
    lineHeight: 1.245,
    color: theme.colors.primary,
    'html[lang^=zh] &': {
      fontSize: 40,
    },
  },
  authorStyle: {
    fontSize: 20,
    color: theme.colors.disabled,
    marginBottom: 60,
    fontWeight: 300,
    '& a': {
      textDecoration: 'none',
      color: theme.colors.disabled,
    },
    'html[lang^=zh] &': {
      fontSize: 16,
    },
  },
  imageStyle: {
    width: '100%',
    overflow: 'hidden',
    paddingBottom: 60,
    '& img': {
      margin: 0,
      width: ' 100%',
    },
  },
  contianerContent: {
    maxWidth: 770,
    margin: [[0, 'auto']],
    textAlign: 'left',
    fontSize: 16,
    color: theme.colors.text,
    '& p': {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
    '& iframe, & p iframe': {
      height: 220,
    },
    '& a': {
      color: theme.colors.secondary,
      textDecoration: 'none',
    },
    '& img': {
      margin: [[30, 0]],
    },
    '& h1': {
      lineHeight: 1.18,
    },
    '& h2': {
      lineHeight: 1.18,
      fontSize: '3.4rem',
    },
    '& h3': {
      lineHeight: 1.18,
      fontSize: '2.8rem',
    },
    '& h4': {
      lineHeight: 1.18,
      fontSize: '2.4rem',
    },
    '& h5': {
      lineHeight: 1.18,
      fontSize: '2rem',
    },
    '& h6': {
      lineHeight: 1.18,
      fontSize: '1.8rem',
    },
    '& ol': {
      listStyleType: 'decimal',
      listStylePosition: 'outside',
      marginLeft: '1.5rem',
    },
    '& ul': {
      listStyleType: 'disc',
      listStylePosition: 'outside',
      marginLeft: '1.5rem',
    },
  },
  tagsStyle: {
    display: 'flex',
    marginTop: '3rem',
  },
  tagStyle: {
    color: theme.colors.disabled,
    lineHeight: 1.5,
    marginRight: 10,
    '&::before': {
      content: '"#"',
      fontSize: '16px',
      position: 'relative',
    },
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 60,
  },
  breadcrumb: {
    paddingTop: '3rem',
    marginBottom: '1.7rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      paddingTop: '2rem',
      marginBottom: '1.5rem',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    containerFluid: {
      padding: [[70, 0, 127]],
      width: '100%',
      textAlign: 'center',
    },
    contianerContent: {
      '& iframe, & p iframe': {
        height: 430,
      },
    },
    titleStyle: {
      marginBottom: '2.2rem',
      lineHeight: 1.2,
      'html[lang^=zh] &': {
        fontSize: 40,
      },

    },
    imageStyle: {
      width: 1170,
    },
  },
}))

export default useStyles
