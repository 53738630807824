import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[15, 20, 40]],
  },
  container: {
    textAlign: 'center',
    paddingBottom: '2rem',
  },
  pagination: {
    marginTop: '6.3rem',
    marginBottom: '5rem',
  },
  breadcrumb: {
    paddingTop: '3rem',
    marginBottom: '1.7rem',
    [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
      paddingTop: '2rem',
      marginBottom: '1.5rem',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.tablet}px)`]: {
    containerFluid: {
      padding: [[0, 0, 35]],
      width: '100%',
      textAlign: 'center',
    },
    container: {
      width: '100%',
      margin: 0,
      display: 'flex',

      '& article': {
        flex: '1 1 66%',
        padding: '0 2.5rem 0 0',
      },
      '& aside': {
        flex: '1 1 30%',
        padding: '13rem 0 0 6rem',
      },
    },
    pagination: {
      marginTop: '6rem',
      paddingLeft: '52%',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    container: {
      width: 1170,
      margin: [[0, 'auto']],
      display: 'flex',

      '& article': {
        flex: '1 1 66%',
        padding: '0 2.5rem 0 0',
      },
      '& aside': {
        flex: '1 1 30%',
        padding: '13rem 0 0 6rem',
      },
    },
  },
}))

export default useStyles
