import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    padding: '4rem 0 0',
    textAlign: 'left',
    '&:first-child': {
      padding: '6rem 0 0',
    },
  },
  titleStyle: {
    fontSize: '2.6rem',
    fontWeight: 'bold',
    color: theme.colors.primary,
    '& a': {
      lineHeight: 1.18,
      color: 'currentColor',
      textDecoration: 'none',
      '&:hover': {
        color: theme.colors.secondary,
      },
    },
  },
  authorStyle: {
    fontSize: '1.6rem',
    lineHeight: 1.5,
    color: theme.colors.disabled,
    marginBottom: '4rem',
    fontWeight: 300,
    '& a': {
      textDecoration: 'none',
      color: theme.colors.disabled,
      '&:hover': {
        color: theme.colors.secondary,
      },
    },
  },
  containerImg: {
    marginBottom: '2.5rem',
    '& img': {
      width: '100%',
      marginBottom: 0,
    },
  },
  containerText: {
    '& p': {
      color: theme.colors.text,
      fontWeight: 400,
      fontSize: '1.6rem',
      lineHeight: 1.5,
      textAlign: 'left',
    },
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    container: {
      padding: '6rem 0 0',
      textAlign: 'center',
      '&:first-child': {
        padding: '4.2rem 0 0',
      },
    },
    titleStyle: {
      fontSize: '3rem',
    },
  },
}))

export default useStyles
