/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import moment from 'moment'
import React from 'react'
import _ from 'lodash'
import { Trans } from 'react-i18next'
import {
  Link,
  SEO,
  Breadcrumb,
  Layout,
  Loading,
  Carousel,
} from '../../ui'
import useStyle from './article-style'

function ArticleView({
  // the page will be ready after loading article data
  pageReady,
  // article data
  article,
  breadcrumb,
  seoDescription,
  seoMeta,
  seoTitle,
  seoLinks,
}) {
  // const { t } = useTranslation()
  const {
    articleCategories = [],
    content = '',
    defaultImage,
    publishStartDate = Date(),
    tags = [],
    title = '',
    author,
    images = [],
  } = article
  const articleCategory = _.first(articleCategories) || {}
  const date = moment(publishStartDate).format('DD MMM YYYY')
  const categoryUrl = `/articles/category/${articleCategory.code}`
  const styles = useStyle()

  return (
    <Layout>
      {pageReady ? (
        <>
          <SEO
            description={seoDescription}
            title={seoTitle}
            meta={seoMeta}
            link={seoLinks}
          />
          {
            _.isEmpty(breadcrumb)
              ? ''
              : (
                <Breadcrumb links={breadcrumb} classN={styles.breadcrumb} />
              )
          }
          <div className={styles.containerFluid}>
            <div>
              <h1 className={styles.titleStyle}>{title}</h1>
              <p className={styles.authorStyle}>
                <Trans
                  i18nKey="screens.articles.postBy"
                >
                  Posted by
                  {{ author }}
                  -
                  {{ date }}
                  -
                  <Link to={categoryUrl}>
                    {{ category: articleCategory.name }}
                  </Link>
                </Trans>
              </p>
              { images.length > 0 && (
              <div className={styles.imageStyle}>
                <Carousel
                  images={images}
                  slidesToShowDesktop={1}
                  carouselSettings={{
                    responsive: [],
                  }}
                  imageBoxClassName=""
                  autoplay
                  darkDots
                  classNaneCarousel="heroCarousel"
                />
              </div>
              )}
              <div className={styles.contianerContent}>
                <article dangerouslySetInnerHTML={{ __html: content }} />
                <div className={styles.tagsStyle}>
                  {tags.map((tag) => (
                    <Link to={`/articles/tag/${tag}/`} key={`article-tag-${tag}`}>
                      <p className={styles.tagStyle}>
                        {tag}
                      </p>
                    </Link>
                  ))}
                </div>
                {/*
                TODO team api has to give the way to implement this feature
                by now we don't have any infomation to get this.

                <div className={styles.buttonBox}>
                  <ArticleButton text={t('screens.article.pre')} onClick={() => {}} left />
                  <ArticleButton text={t('screens.article.next')} onClick={() => {}} right />
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </Layout>
  )
}

export default ArticleView
