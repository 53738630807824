import _ from 'lodash'
import React from 'react'
import { Trans } from 'react-i18next'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from '../../../ui'
import useStyle from './article-card-style'

const ArticleCardView = ({ article }) => {
  const {
    container, containerImg, containerText, authorStyle, titleStyle,
  } = useStyle()
  const articleImage = _.get(article, 'defaultImage.versions.ecomMedium')
  const author = _.get(article, 'author', '')
  const date = moment(_.get(article, 'publishStartDate')).format('DD MMM YYYY')
  const category = _.get(article, 'articleCategories.0.name', '')
  const url = `/articles/category/${_.get(article, 'articleCategories.0.code', '')}/`
  return (
    <div className={container}>
      <h2 className={titleStyle}>
        <Link to={article.canonicalHref}>
          {article.title}
        </Link>
      </h2>
      <p className={authorStyle}>
        <Trans
          i18nKey="screens.articles.postBy"
        >
          Posted by
          {{ author }}
          -
          {{ date }}
          -
          <Link to={url}>
            {{ category }}
          </Link>
        </Trans>
      </p>
      {articleImage && (
        <div className={containerImg}>
          <Link to={article.canonicalHref}>
            <img src={articleImage} alt="" />
          </Link>
        </div>
      )}
      <div className={containerText}>
        <p>{article.excerpt}</p>
      </div>
    </div>
  )
}

ArticleCardView.propTypes = {
  article: PropTypes.shape({
    title: PropTypes.string,
    author: PropTypes.string,
    publish_start_date: PropTypes.string,
    category: PropTypes.array,
    defaultImage: PropTypes.object,
    excerpt: PropTypes.string,
  }),
}

ArticleCardView.defaultProps = {
  article: {
    title: undefined,
    author: null,
    publish_start_date: '',
    category: [],
    defaultImage: {},
    excerpt: '',
  },
}

export default ArticleCardView
