/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import {
  flow, first, split,
} from 'lodash/fp'
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import URI from 'urijs'
import { cancelRequest, useArticles, useArticlesCategories } from 'react-omnitech-api'
import { useLink } from '../../hook'
import ArticlesView from './articles-view'

function ArticlesController(props) {
  const {
    filterType, location, slug, pathContext,
  } = props

  // prepare hook
  const { fetchArticles, fetchArticlesTags } = useArticles()
  const { fetchArticlesCategories } = useArticlesCategories()
  const { navigate } = useLink()
  const { t } = useTranslation()

  // internal states
  const [articles, setArticles] = useState([])
  const [tags, setTags] = useState([])
  const [slugTitle, setSlugTitle] = useState('')
  const [categories, setCategories] = useState([])
  const [breadcrumb, setBreadcrumb] = useState([])

  const [pagination, setPagination] = useState({})
  const [pageReady, setPageReady] = useState(false)

  // local variable
  const seoTitle = t('screens.articles.seo.title')

  // get query for api call
  const filterTypeQuery = useMemo(() => {
    // query paramter
    const url = new URI(_.get(location, 'href', ''))
    const { page } = url.search(true)

    // category / tag filter
    const filterKey = filterType === 'category' ? 'article_category_code_eq' : 'tag'
    const filterValue = flow(
      split('-'),
      first,
    )(slug)
    return {
      [filterKey]: filterValue,
      page,
      pageSize: 6,
    }
  }, [filterType, location, slug])

  /**
   * fetchArticlesApi
   * get article content group data from API
   */
  const fetchArticlesApi = useCallback(async () => {
    try {
      // api call option
      const option = {
        includes: [
          'article_categories',
        ].join(','),
        ...filterTypeQuery,
      }
      // call api
      const { articles: data, pagination: paginationData } = await fetchArticles(option)
      setArticles(data)
      setPagination(paginationData)
    } catch (error) {
      // TODO: when fail to load content group, what should be shown?
    } finally {
      setPageReady(true)
    }
  }, [fetchArticles, filterTypeQuery])

  /**
   * fetchTagsApi
   * get tags data from API
   */
  const fetchArticlesTagsApi = useCallback(async () => {
    try {
      // api call option (no options by the moment. but we keep empty for the future)
      const option = {
      }
      // call api
      const { tags: data } = await fetchArticlesTags(option)
      setTags(data)
    } catch (error) {
      // TODO: when fail to load content group, what should be shown?
    } finally {
      setPageReady(true)
    }
  }, [fetchArticlesTags])

  /**
   * fetchTagsApi
   * get tags data from API
   */
  const fetchArticlesCategoriesApi = useCallback(async () => {
    try {
      // api call option (no options by the moment. but we keep empty for the future)
      const option = {
        includes: [
          'article_count',
        ].join(','),
      }
      // call api
      const { articlesCategories: data } = await fetchArticlesCategories(option)
      setCategories(data)
    } catch (error) {
      // TODO: when fail to load content group, what should be shown?
    } finally {
      setPageReady(true)
    }
  }, [fetchArticlesCategories])

  // TODO: load articles list next page
  /**
   * create breacrumb
   */

  const baseLink = {
    text: t('screens.articles.baseBreadcrumb'),
    url: '/articles/',
  }

  useEffect(() => {
    if (slug && categories.length > 0) {
      let textSlug = ''
      switch (filterType) {
        case 'category':
          textSlug = categories.filter((category) => category.code === slug)[0].name
          break;
        case 'tag':
          textSlug = `#${slug}`
          break;
        default:
          break;
      }
      setSlugTitle(textSlug)
    }
  }, [slug, categories])

  useEffect(() => {
    if (slug && categories.length > 0) {
      let textLink = ''
      const tempBreadcrumb = []
      if (filterType === 'category') {
        textLink = categories.filter((category) => category.code === slug)[0].name
      } else {
        textLink = slug
      }
      const slugLink = {
        text: textLink,
        url: undefined,
      }
      tempBreadcrumb.push(baseLink, slugLink)
      setBreadcrumb(tempBreadcrumb)
    }
  }, [slug, categories])

  /**
   * handel pagination
   */
  const updateUrlParams = useCallback((options) => {
    const url = new URI(location.href)
    url.search({
      ...url.search(true),
      ...options,
    })
    const newUrl = url.href().replace(`${url.protocol()}://${url.host()}/${pathContext.locale}`, '')
    navigate(newUrl, { replace: false })
  }, [location])

  function handlePageClick(selectedPage) {
    const { currentPage } = pagination
    if (currentPage === selectedPage) return
    const newFilterOption = {
      page: selectedPage,
    }
    updateUrlParams(newFilterOption)
  }

  /**
   * load article content group when page loaded
   */
  useEffect(() => {
    fetchArticlesApi()
    fetchArticlesTagsApi()
    fetchArticlesCategoriesApi()
    return function fetchArticlesApiCleanUp() {
      cancelRequest.cancelAll([
        'fetchArticles',
        'fetchArticlesTags',
        'fetchArticlesCategories',
      ])
    }
  }, [fetchArticlesApi, fetchArticlesTagsApi, fetchArticlesCategoriesApi])

  const viewProps = {
    articles,
    pagination,
    pageReady,
    tags,
    categories,
    slug: slugTitle,
    breadcrumb,
    seoTitle,
    onPageClick: handlePageClick,
    // onPageSizeUpdate: handlePageSizeUpdate,
  }

  return (
    <ArticlesView {...viewProps} />
  )
}

export default ArticlesController
